<template>
  <div>
    <load-profile></load-profile>
    <bet-setting-menu></bet-setting-menu>

    <b-card>
      <validation-observer ref="updateForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="updateForm"
          @submit="submit"
          :form="$refs.updateForm"
          :disabled="invalid"
        >
          <b-row>
            <b-col lg="6" v-for="(item, i) in data" :key="`input-${i}`">
              <b-table-simple
                class="odds-table"
                bordered
                responsive
                :class="{
                  rain: item.selectType == 1,
                  norain: item.selectType == 2,
                }"
              >
                <b-thead>
                  <b-tr>
                    <th colspan="4" class="text-center">
                      <h5 class="text-light">
                        {{
                          item.selectType == 1
                            ? $t("field.rain")
                            : $t("field.noRain")
                        }}
                      </h5>
                    </th>
                  </b-tr>
                  <b-tr>
                    <b-th class="text-center">{{ $t("field.fromOdds") }}</b-th>
                    <b-th class="text-center">{{ $t("field.toOdds") }}</b-th>
                    <b-th class="text-center">{{ $t("field.maxOdds") }}</b-th>
                    <b-th class="text-center remove"></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(list, j) in item.list" :key="`row-${j}`">
                    <b-td>
                      <validation-provider
                        #default="{ errors }"
                        :vid="`from-odds-${i}-${j}`"
                        :name="$t('field.fromOdds')"
                        :rules="`required|decimal:2`"
                      >
                        <b-form-input
                          v-model="list.startOdd"
                          :placeholder="$t('field.fromOdds')"
                          :name="`from-odds-${i}-${j}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-td>
                    <b-td>
                      <validation-provider
                        #default="{ errors }"
                        :vid="`to-odds-${i}-${j}`"
                        :name="$t('field.toOdds')"
                        :rules="`required|decimal:2`"
                      >
                        <b-form-input
                          v-model="list.endOdd"
                          :placeholder="$t('field.toOdds')"
                          :name="`to-odds-${i}-${j}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-td>
                    <b-td>
                      <validation-provider
                        #default="{ errors }"
                        :vid="`add-odds-${i}-${j}`"
                        :name="$t('field.maxOdds')"
                        :rules="`required|decimal:2`"
                      >
                        <b-form-input
                          v-model="list.maxOdd"
                          :placeholder="$t('field.maxOdds')"
                          :name="`add-odds-${i}-${j}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                          class="add-odds"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-td>
                    <b-td class="remove">
                      <b-button
                        variant="outline-danger"
                        @click="removeRow(i, j)"
                        size="sm"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="4">
                      <b-button
                        variant="outline-secondary"
                        block
                        @click="addNewRow(i)"
                      >
                        {{ $t("button.add") }}
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('update', 'bet-setting')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NSingleSelect from "@/components/NSingleSelect";
import NInput from "@/components/NInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import BetSettingMenu from "@/views/betSetting/BetSettingMenu.vue";

const BetSettingRepository = Repository.get("betSetting");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NSingleSelect,
    NAsyncSingleSelect,
    BetSettingMenu,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    addNewRow(i) {
      this.data[i].list.push({
        startOdd: null,
        endOdd: null,
        maxOdd: null,
      });
    },
    removeRow(i, j) {
      this.data[i].list.splice(j, 1);
    },
    show() {
      BetSettingRepository.showLimit().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = [...data];
        }
      });
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          BetSettingRepository.updateLimit(this.data)
            .then((response) => {})
            .catch((error) => {
              if (error.response?.data?.message) {
                this.$refs.updateForm.setErrors(error.response?.data?.message);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>